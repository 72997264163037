@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.threshold-container {
  position: relative; 
  display: inline-block; 
}
.label-status {
  padding: 8px 14px;
  border-radius: 8px;
  background: #f1f1f1;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.label-status:hover {
  background: #e0e0e0;
}
.confirm-popup {
  position: absolute;
  top: 100%; /* Position below the label */
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 10px 14px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1000;
  min-width: 140px;
  white-space: nowrap;
}


.confirm-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.confirm-buttons button {
  padding: 6px 14px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.confirm-yes {
  background: #4caf50;
  color: white;
}

.confirm-yes:hover {
  background: #43a047;
}

.confirm-no {
  background: #e57373;
  color: white;
}

.confirm-no:hover {
  background: #d32f2f;
}




.threshold-LabelStatus {
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  border: 1px solid black;
  color: black;
}

.threshold-LabelStatus:hover {
  transform: scale(1.05);
}

.threshold-red {
  color: red;
  font-weight: bold;
  border: 1px solid red;
}