.webhook-settings-container {
  max-width: 1200px;
  margin: auto;
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.webhook-settings-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  justify-items: center;
  align-items: center;
}

.search-bar {
  margin-bottom: 15px;
  width: 50%;
}

.webhook-url-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.masked-url {
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.status-active {
  color: green;
}

.status-inactive {
  color: red;
}

.edit-webhook-drawer {
  width: 300px;
  padding: 20px;
}

.webhook-input {
  margin-bottom: 15px;
}

.status-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}


.edit-webhook-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px; /* Small, centered size */
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.webhook-input {
  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.save-button {
  flex: 1;
  background: #0a0a0a;
  color: white;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.save-button:hover {
  background: #3b3939;
}

.cancel-button {
  flex: 1;
  color: #666;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.cancel-button:hover {
  background: #f4f4f4;
}

